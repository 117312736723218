.comment-header-wrapper {
  display: flex;
  align-items: center;
  gap: 50px;
  color: #082064;
  margin-bottom: 20px;
}

.comment-wrapper {
  color: #6d7aa7;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d1d180;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  width: 70px;
  gap: 5px;
  /* color: #6d7aa7; */
}

.subject-class {
  font-weight: 600;
}

.comment-section-body {
  margin-top: 20px;
}

.comment-description {
  height: 75px;
  overflow-y: auto;
}

.status-value-container {
  padding: 5px 15px;
  background-color: #ffecc8;
}

.priority-value-container {
  padding: 5px 15px;
  background-color: #d9e6ff;
}
