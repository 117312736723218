.progress-bar-container {
  display: flex;
  width: 80%;
  height: 10px;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  background-color: #ba2b1b;
}

.progress-section {
  height: 100%;
}

.red {
  background-color: #ba2b1b;
  border-radius: 5px;
}

.green {
  background-color: #74cc61;
  border-radius: 5px;
}

.white {
  background-color: #ededed;
}
