/* Container for the entire comment section */
.comment-section {
  /* max-width: 600px;
  margin: auto; */
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

/* Title */
.comment-section h2 {
  margin-bottom: 16px;
}

/* Comment input container */
.comment-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

/* Comment input field */
.comment-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
}

/* Comment input button */
.comment-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

/* Comment wrapper */
.comment {
  margin-bottom: 16px;
}

/* Comment header */
.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

/* Profile picture */
.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-right: 12px;
}

/* Username */
.comment-user {
  font-weight: bold;
  font-size: 14px;
}

/* Timestamp */
.comment-timestamp {
  font-size: 12px;
  color: #888;
}

/* Comment text */
.comment-text {
  margin-left: 52px;
  font-size: 14px;
  margin-bottom: 8px;
}

/* Reply link */
.reply-link {
  margin-left: 52px;
  font-size: 12px;
  color: #007bff;
  cursor: pointer;
}

/* Replies container */
.replies {
  margin-left: 52px;
  margin-top: 8px;
}

/* File input styling */
.file-input {
  margin-left: 8px;
  border: none;
}

/* File display styling */
.comment-file {
  margin-left: 52px;
  margin-top: 8px;
}

.comment-file a {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
}

.comment-file a:hover {
  text-decoration: underline;
}

/* Comment input container */
.comment-input-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 16px;
}

/* Comment input */
.comment-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 40px; /* Leave space for the attachment icon */
}

/* Attachment icon wrapper */
.attachment-icon-wrapper {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

/* Attachment icon */
.attachment-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* File input (hidden) */
.file-input-hidden {
  display: none;
}

/* Comment button */
.comment-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 8px;
  cursor: pointer;
}

/* File display styling */
.comment-file {
  margin-left: 52px;
  margin-top: 8px;
}

.comment-file a {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
}

.comment-file a:hover {
  text-decoration: underline;
}

.file-previews {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.file-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-preview-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.file-preview-icon {
  font-size: 50px;
}

.file-name {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.internal-comment {
  background-color: #e8f5e9; /* Light green background for internal comments */
  border-left: 4px solid #4caf50; /* Green border */
  padding-left: 8px;
}
