.parameter-wrapper-notification {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.email-content-wrapper {
  background-color: hsl(0, 0%, 90%);
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
[data-theme="dark"] .email-content-wrapper
{
  background-color: hsl(0deg 0% 22.52%);
}
.close-icon-parameter {
  cursor: pointer;
}
