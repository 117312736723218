.main-body,
.main-body-dark {
  background-color: #f6f6fc;
  padding: 20px;
  overflow: auto;
  position: relative;
}

.main-body-dark {
  /* background-color: #000; */
  background-color: #161618;
}

.layout-wraper {
  display: grid;
  grid-template-columns: 1fr 7fr;
  height: 100vh;
}
.main-body-dark .bg-white {
  background-color: #1c1c1e !important;
}
.main-body-dark .MuiTableRow-head
{
  background: #242425 !important
}