.notification-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #efefef;
  transition: background-color 0.3s ease;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #efefef;
  align-items: center;
}

/* .notification-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dbdada;
  transition: background-color 0.3s ease;
} */

.notification-container.read {
  background-color: #e7e7e7; /* Shade background for read notifications */
}

.notification-container.unread {
  background-color: white; /* Default color for unread notifications */
}
.time-period-selector
{
  display: flex;
  align-items: center;
  justify-content: left;
}
.dropdownnew-style button
{
  --bs-btn-color: #000000;
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #dee2e6;
    border-radius: 10px !important;
    min-width: 175px;
}
.dropdownnew-style button:hover
{
  color: #000000;
  background-color: #ffffff;
  border-color: #dee2e6;
  border-radius: 10px !important;
}
.dropdownnew-style .btn:first-child:active
{
  color: #000000;
  background-color: #ffffff;
  border-color: #dee2e6;
}
.dropdownnew-style .btn:first-child:active:focus-visible 
{
  box-shadow: none;
}
.dropdownnew-style .btn-check:checked+.btn, .dropdownnew-style .btn.active, .dropdownnew-style .btn.show, .dropdownnew-style .btn:first-child:active, :not(.btn-check)+.btn:active, .dropdownnew-style .btn:focus-visible
{
  color: #000000;
  background-color: #ffffff;
  border-color: #dee2e6;
  box-shadow: none;
}
.height-set .dropdown-menu
{
  max-height: 400px;
  overflow-y: scroll;
}
.frequency-selector
{
  display: flex;
  align-items: center;
  justify-content: start;
}
.frequency-selector label
{
  margin-right: 15px;
}
.frequency-selector input 
{
  margin-right: 5px;
}
.select__control
{
  max-height: 50px;
    overflow-y: scroll;
}
.filter-img .button-common img
{
  margin-right: 10px;
}
.modal-dropdownnew-style button 
{
  display: flex;
  width: 100%;
}
.modal-dropdownnew-style
{
  width: 100%;
}
#dropdown-time-period
{
  text-transform: capitalize;
}