.configure-header {
  height: 70px;
  background: #fff;
  padding: 24px 0;
  position: fixed;
  top: 0;
  left: 274px;
  z-index: 100;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.configure-body {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.notification-card-menu {
  padding: 5px;
  width: 100%;
  display: block;
  color: #082064;
  cursor: pointer;
}

.notification-card-menu-active {
  background: #00b7f15e;
  font-weight: bold;
}

.menu-buttons {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 220px;
  cursor: pointer;
}

.menu-button-items {
  display: block;
  margin: 0;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-bottom: 1px solid #f1f1f1;
  background: transparent;
  color: #333;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.menu-button-items:hover {
  background: #e7e7e7;
}
