.sidemenu-wrapper {
  display: flex;
  margin: auto;
  align-items: center;
  gap: 12px;
  padding: 5px 20px;
  border-radius: 0px;
  color: #fff;
  /* background: #082064; */
  cursor: pointer;
}

.sidemenu-wrapper img {
  width: 20px;
}

.active-sidemenu {
  background: #0a2574;
}

.active-sidemenu .icon-box {
  background: #36c3ce;
}

.sidebar-position {
  position: relative;
}

.copyright-position {
  position: absolute;
  bottom: 0;
}

.notification-dot-wrapper {
  display: flex;
  gap: 15px;
}

.notification-dot {
  height: 25px;
  width: 25px;
  font-size: 12px;
  display: grid;
  place-items: center;
  border-radius: 15px;
  background-color: red;
}
