.graph-heading {
  color: #9fb0d0;
  font-weight: 600;
  font-size: 20px;
}

.dashboard-card-wraper {
  height: 300px;
  margin-bottom: 20px;
}

/* Ensure the background color is retained in fullscreen */
:fullscreen {
  background-color: white; /* Set to your desired background color */
}

.dashboard-fullscreen {
  background-color: #f6f6fc; /* Maintain consistent background */
}

[data-theme="dark"] .dashboard-fullscreen {
  background-color: #161618;
}

.fullscreen-button-color {
  color: black;
}

[data-theme="dark"] .fullscreen-button-color {
  background-color: white;
}
