.button-common {
  /* gap: 5px; */
  font-weight: 600;
  border-radius: 5px;
  border: none;
}

.button-icon {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}

.large-button {
  width: 140px;
  height: 40px;
  text-align: center;
}

.primary {
  color: white;
  background-color: #082064;
}
/* [data-theme="dark"] .primary
{
  background-color: #10151f;
} */

.secondary {
  color: #082064;
  background-color: white;
  /* border: 1px solid #082064; */
  border: 1px solid #8893b7;
}

.danger {
  background-color: red;
  color: white;
}

.primary:hover {
  background-color: #0a3a8a; /* Lighter shade of blue */
  color: #e0e0e0; /* Light grey */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.secondary:hover {
  background-color: #d3d3d3; /* Light grey */
  color: #082064; /* Keep the text blue */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.equal-button {
  height: 45px;
  width: 45px;
  border: 1px solid #8893b7;
}

.extra-small {
  height: 20px;
  width: 20px;
  text-align: center;
}
