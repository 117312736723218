.battery-container {
  /* display: flex;
  align-items: center;
  gap: 10px; */
  /* display: grid;
  place-items: center;
  gap: 5px;
  position: relative; */
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  height: 80%;
  align-items: center;
  justify-content: center;
}

.battery-body {
  /* width: 150px;
  height: 40px; */
  width: 75px;
  height: 150px;
  /* border: 2px solid #9fb0d0; */
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  align-items: end;
  background: #F2F5FA;
}

.battery-level {
  /* height: 100%; */
  width: 100%;
  border-radius: 3px 0 0 3px;
  transition: width 0.3s ease;
}

.battery-head {
  /* width: 10px;
  height: 20px; */
  width: 35px;
  height: 10px;
  border: 10px solid #F2F5FA;
  /* border-left: none; */
  border-bottom: none;
  margin-left: -2px;
}

.battery-text {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  bottom: 40%;
}
